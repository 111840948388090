import React, {useEffect, useRef, useState} from 'react';
import {checkFileChanges, validateSerial} from '../../Helpers/Helpers';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SubjectIcon from '@mui/icons-material/Subject';
import {CardActions, Checkbox, Divider, FormControlLabel, Grid} from "@mui/material";
import Button from "@mui/material/Button";

import {
    process_vm_request as handleVMRequest
} from '../../Helpers/RequestProcessors/VMRequestProcessing';
import ProgressBarDialog from "../Dialogs/ProgressBarDialog";
import SuccessDialog from "../Dialogs/SuccessDialog";
import ErrorDialog from "../Dialogs/ErrorDialog";
import VersionSelector from "../Statics/VersionSelector";
import UploadErrorDialog from "../Dialogs/UploadErrorDialog";

function VMRequestForm({ticketData, fileshareData, profile}) {
    // Initialize the 'subject' state with the value from 'ticketData.subject' if it exists, or an empty string otherwise
    const [subject, setSubject] = useState(ticketData.subject || '');
    const [hostnameList, setHostnameList] = useState(['']);

    const [adaptCoreCount, setAdaptCoreCount] = useState(false);
    const [coreCount, setCoreCount] = useState('');

    const [adaptRAMSize, setAdaptRAMSize] = useState(false);
    const [ram, setRam] = useState('');

    const [adaptDiskSpace, setAdaptDiskSpace] = useState(false);
    const [diskSpace, setDiskSpace] = useState('');

    const [moveVM, setMoveVM] = useState(false);
    const [moveReason, setMoveReason] = useState('');

    const [files, setFiles] = useState([]);
    const [openProgressBar, setOpenProgressBar] = useState(false);

    // Success Dialog open / close
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openErrorUploadDialog, setOpenErrorUploadDialog] = useState(false);
    const [images, setImages] = useState([]);

    const [progress, setProgress] = useState(0); // Add progress state
    const [subjectError, setSubjectError] = useState(false);
    const [openMissingFieldsDialog, setOpenMissingFieldsDialog] = useState(false);

    // Add a ref for the hidden input
    const imagesInputRef = useRef(null);

    const handleAdaptCoreCountChange = (event) => {
        setAdaptCoreCount(event.target.checked);
        if (!event.target.checked) {
            setCoreCount(''); // Clear the core count if the checkbox is unchecked
        }
    };

    const handleCoreCountChange = (event) => {
        const value = event.target.value;
        if (value === '' || /^[0-9\b]+$/.test(value)) {
            setCoreCount(value);
        }
    };

    const handleAdaptRAMSizeChange = (event) => {
        setAdaptRAMSize(event.target.checked);
        if (!event.target.checked) {
            setRam(''); // Clear the RAM size if the checkbox is unchecked
        }
    };

    const handleRAMChange = (event) => {
        const value = event.target.value;
        if (value === '' || /^[0-9\b]+$/.test(value)) {
            setRam(value);
        }
    };

    const handleAdaptDiskSpaceChange = (event) => {
        setAdaptDiskSpace(event.target.checked);
        if (!event.target.checked) {
            setDiskSpace(''); // Clear the disk space if the checkbox is unchecked
        }
    };

    const handleDiskSpaceChange = (event) => {
        const value = event.target.value;
        if (value === '' || /^[0-9\b]+$/.test(value)) {
            setDiskSpace(value);
        }
    };

    const handleMoveVMChange = (event) => {
        setMoveVM(event.target.checked);
        if (!event.target.checked) {
            setMoveReason(''); // Clear the reason if the checkbox is unchecked
        }
    };

    const handleMoveReasonChange = (event) => {
        setMoveReason(event.target.value);
    };

    const handleAddHostname = () => {
        setHostnameList([...hostnameList, '']);
    };

    const handleHostnameChange = (index, event) => {
        const newHostnames = [...hostnameList];
        newHostnames[index] = event.target.value;
        setHostnameList(newHostnames);
    };

    const convertArrayToString = (arr) => {
        // Filter out empty or whitespace-only items
        const validItems = arr.filter(item => item && item.trim() !== '');

        // If no valid items exist, return null
        if (validItems.length === 0) {
            return null;
        }

        // Join the valid items with " | "
        return validItems.join(' | ');
    };

    const handleButtonClick = async () => {

        const hostnameString = convertArrayToString(hostnameList);

        const hasAtLeastOneHostname = hostnameList.some(hostname => hostname && hostname.trim() !== '');

        if (!hasAtLeastOneHostname) {
            setOpenMissingFieldsDialog(true);
            alert('Please enter at least one valid hostname.');
            return;
        }

        let requiredFields = [
            { value: hasAtLeastOneHostname, name: 'Hostname(s)' },
        ];

        // Create an array to store the missing fields
        const missingFields = [];

        requiredFields.forEach(field => {
            if (!field.value) {
                missingFields.push(field.name);
                if (field.setError) {
                    field.setError(true);
                }
            }
        });

        if (missingFields.length > 0) {
            // Log the missing fields to the console
            setOpenMissingFieldsDialog(true);
            alert('Please fill out the following fields:\n' + missingFields.join('\n'));
            console.log('Missing Fields: ', missingFields.join(', '));
            return;
        }

        // Show the progress bar dialog before starting the upload
        setOpenProgressBar(true);

        // Wait for the progress bar dialog to be fully opened
        await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for 1 second as an example

        // Handle file upload and progress updates here
        await handleVMRequest(
            {
                ticketData,
                hostnameString,
                adaptCoreCount,
                coreCount,
                adaptRAMSize,
                ram,
                adaptDiskSpace,
                diskSpace,
                moveVM,
                moveReason,
                images,  // Include images in the form data
            },
            files,
            setOpenProgressBar,
            setOpenSuccessDialog,
            setOpenErrorDialog,
            setOpenErrorUploadDialog,
        );
    };

    const handleClose = () => {
        // Commented out so users can not close the completed form
        //setOpenSuccessDialog(false);
    };


    const handleCloseError = () => {
        setOpenErrorDialog(false);
    };


    // Gets executed once at startup
    useEffect(() => {
        localStorage.removeItem('localFileData');
    }, []);

    // Convert the 'images' state to a JSON string
    const imagesJson = JSON.stringify(images);

    useEffect(() => {
        if (imagesInputRef.current) {
            // Update the value of the ref
            imagesInputRef.current.value = imagesJson;
        }
    }, [imagesJson]); // Run this effect whenever 'imagesJson' changes



    return (

        <form onSubmit={handleButtonClick}>

            <ProgressBarDialog
                open={openProgressBar}
                onClose={() => setOpenProgressBar(false)}
                progress={progress}
                setProgress={setProgress} // Pass the setProgress function here
            />

            <Card style={{marginBottom: 100}}>
                <center>
                    <Typography style={{marginBottom: 20, marginTop: 30, fontFamily: 'Arimo', fontWeight: 'bold'}}
                                variant="h5">
                        Virtual Machine Administration Request
                    </Typography>
                    <Typography style={{fontFamily: 'Arimo'}}>
                        Request changes for Virtual Machines from the Virtual Machine Administration
                        Team.
                    </Typography>

                    <Typography style={{marginBottom: 20, fontFamily: 'Arimo'}}>
                        This request is only needed for adjusting (increasing / decreasing) system resources.
                    </Typography>

                    <Typography style={{marginBottom: 20, fontFamily: 'Arimo'}}>
                        <b>Be aware that VMs can be <i>created</i> by all users without an administrator.</b>
                    </Typography>
                </center>

                {/* Hidden input to cache local images */}
                <input type="hidden" name="images" ref={imagesInputRef}/>

                <div style={{border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin: 50}}>
                    <div style={{
                        background: '#202D4F',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        padding: '10px',
                        textAlign: 'center'
                    }}>
                        <Typography style={{fontFamily: 'Arimo', color: 'white'}} variant="h6">
                            Affected Virtual Machines
                        </Typography>
                    </div>

                    <div style={{margin: 20}}>
                        <Typography style={{fontFamily: 'Arimo', marginBottom:20}} gutterBottom>
                            Enter the hostnames of the existing virtual machines. Click <b>"Add Machine"</b> to add more hostnames if multiple machines are affected by the same change.
                        </Typography>
                        {hostnameList.map((hostname, index) => (
                            <TextField
                                key={index}
                                label={`Hostname ${index + 1}`}
                                variant="outlined"
                                fullWidth
                                value={hostname}
                                onChange={(event) => handleHostnameChange(index, event)}
                                style={{marginBottom: 10}}
                            />
                        ))}
                        <Button
                            variant="contained"
                            onClick={handleAddHostname}
                            style={{marginTop: 10, backgroundColor: '#172038', fontFamily:'Arimo'}}
                        >
                            Add Machine
                        </Button>
                    </div>

                </div>

                <div style={{border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin: 50}}>
                    <div style={{
                        background: '#202D4F',
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        padding: '10px',
                        textAlign: 'center'
                    }}>
                        <Typography style={{fontFamily: 'Arimo', color: 'white'}} variant="h6">
                            Required Changes
                        </Typography>
                    </div>
                    <Grid style={{margin: 20}} container spacing={2}>

                        <Grid item xs={10} sm={5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={adaptCoreCount}
                                            onChange={handleAdaptCoreCountChange}
                                            color="primary"
                                        />
                                    }
                                    label="Adapt Processor Core Count"
                                    style={{marginTop:10}}
                                />
                        </Grid>

                        <Grid item xs={10} sm={6}>
                            <TextField
                                label="Required Core Count"
                                variant="outlined"
                                fullWidth
                                value={coreCount}
                                type="number"
                                onChange={handleCoreCountChange}
                                disabled={!adaptCoreCount}
                                style={{ marginTop: 0}}
                            />

                        </Grid>


                        <Grid item xs={10} sm={5}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={adaptRAMSize}
                                        onChange={handleAdaptRAMSizeChange}
                                        color="primary"
                                    />
                                }
                                label="Adapt RAM Size"
                                style={{marginTop:10}}
                            />
                        </Grid>

                        <Grid item xs={10} sm={6}>
                            <TextField
                                label="Required RAM Size (MB)"
                                variant="outlined"
                                fullWidth
                                value={ram}
                                type="number"
                                onChange={handleRAMChange}
                                disabled={!adaptRAMSize}
                                style={{ marginTop: 0}}
                            />
                        </Grid>

                        <Grid item xs={10} sm={5}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={adaptDiskSpace}
                                        onChange={handleAdaptDiskSpaceChange}
                                        color="primary"
                                    />
                                }
                                label="Adapt Disk Space"
                                style={{marginTop:10}}
                            />
                        </Grid>

                        <Grid item xs={10} sm={6}>
                            <TextField
                                label="Required Disk Space (GB)"
                                variant="outlined"
                                fullWidth
                                value={diskSpace}
                                type="number"
                                onChange={handleDiskSpaceChange}
                                disabled={!adaptDiskSpace}
                                style={{ marginTop: 0}}
                            />
                        </Grid>

                        <Grid item xs={11}>
                            <Divider style={{margin: '20px 0'}} />
                        </Grid>



                        <Grid item xs={10} sm={5}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={moveVM}
                                        onChange={handleMoveVMChange}
                                        color="primary"
                                    />
                                }
                                label="Move VM to High Performance Cluster"
                                style={{marginTop:10}}
                            />

                            <Typography style={{marginTop: 10, fontFamily: 'Arimo', color: '#212121', fontSize:12}}>
                                The high performance cluster has a higher CPU clock rate per core compared to the default one. Please let us know why you need the Virtual Machine to be on the high-performance CPU cluster.
                            </Typography>

                        </Grid>

                        <Grid item xs={10} sm={6}>
                            <TextField
                                label="Reason for the VM to need higher CPU rate."
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                value={moveReason}
                                onChange={handleMoveReasonChange}
                                disabled={!moveVM}
                                style={{ marginTop: 0}}
                            />
                        </Grid>

                        <Grid item xs={10}>

                        </Grid>
                    </Grid>

                </div>


                <CardActions>

                    <div style={{marginLeft: 'auto', marginRight: 45, marginTop: -30, marginBottom: 20}}>
                        <Button
                            style={{backgroundColor: '#202D4F'}}
                            variant="contained"
                            onClick={handleButtonClick}
                        >
                            Request Assistance
                        </Button>
                    </div>

                </CardActions>


                <SuccessDialog open={openSuccessDialog} onClose={handleClose}/>
                <UploadErrorDialog open={openErrorUploadDialog} onClose={handleClose}/>
                <ErrorDialog open={openErrorDialog} onClose={handleCloseError}/>

            </Card>
        </form>
    );
}

export default VMRequestForm;
