import React, { useState, useEffect, useRef } from 'react';
import { checkFileChanges, validateSerial, getResponseTime } from '../../Helpers/Helpers';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";

import ApartmentIcon from '@mui/icons-material/Apartment';
import Face3Icon from '@mui/icons-material/Face3';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PeopleIcon from '@mui/icons-material/People';
import LanguageIcon from '@mui/icons-material/Language';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import TranslateIcon from '@mui/icons-material/Translate';
import GroupsIcon from '@mui/icons-material/Groups';

import {
    CardActions, Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    Paper,
    Slider,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import TrixEditor from "../TrixEditor";
import Button from "@mui/material/Button";
import FileUpload from 'react-material-file-upload';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';

// Import the handleButtonClick function from uploadFunctions.js
import {
    process_education_request as handleEducationRequest
} from '../../Helpers/RequestProcessors/EducationRequestProcessing';
import ProgressBarDialog from "../Dialogs/ProgressBarDialog";
import SuccessDialog from "../Dialogs/SuccessDialog";
import ErrorDialog from "../Dialogs/ErrorDialog";
import UploadErrorDialog from "../Dialogs/UploadErrorDialog";
import VersionSelector from "../Statics/VersionSelector";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";


function EducationalRequestForm({ ticketData, fileshareData, profile }) {

    const [end_customer, set_end_customer] = useState( '');
    const [end_customer_company, set_end_customer_company] = useState( '');
    const [end_customer_mail, set_end_customer_mail] = useState( '');
    const [desiredDeliveryDate, setDesiredDeliveryDate] = useState(null);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [directContactEndCustomer, setDirectContactEndCustomer] = useState(false);

    const [productVersion, setProductVersion] = useState(ticketData?.productId || '');

    const [description, setDescription] = useState('');
    const [expectedSolution, setExpectedSolution] = useState('');
    const [requiredHardware, setRequiredHardware] = useState('');
    const [additional_information, set_additional_information] = useState('');

    const [files, setFiles] = useState([]);
    const [prevFiles, setPrevFiles] = useState([]);
    const [openProgressBar, setOpenProgressBar] = useState(false);

    // Success Dialog open / close
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openErrorUploadDialog, setOpenErrorUploadDialog] = useState(false);
    const [images, setImages] = useState([]);

    const [progress, setProgress] = useState(0); // Add progress state
    const [openMissingFieldsDialog, setOpenMissingFieldsDialog] = useState(false);
    const [problem_kind, setProblemKind] = useState(''); // State variable for problem type

    const [trainingType, setTrainingType] = useState('Face-To-Face');

    const [trainingContent, setTrainingContent] = useState('');
    const [customTrainingContent, setCustomTrainingContent] = useState('');

    const [trainingDuration, setTrainingDuration] = useState( '');
    const [trainingLocation, setTrainingLocation] = useState( '');
    const [trainingLanguage, setTrainingLanguage] = useState( '');

    const [attendeeCount, setAttendeeCount] = useState( '');

    const [training_att_skillze, set_training_att_skillze] = useState( ''); // zenon skills
    const [training_att_skillsc, set_training_att_skillsc] = useState( ''); // Scada skills



    // Add a ref for the hidden input
    const imagesInputRef = useRef(null);
    const descriptionRef = useRef(null);
    const what_do_you_wantRef = useRef(null);
    const requiredHardWareRef = useRef(null);
    const additionalInformationRef = useRef(null);

    const handleButtonClick = async () => {

        // Create an array to store the missing fields
        const missingFields = [];

        // Check if all fields are filled out
        const requiredFields = [
            {value: description, name: 'Description'},
            {value: end_customer, name: 'End Customer'},
            {value: end_customer_company, name: 'Company of the End Customer'},
            {value: end_customer_mail, name: 'Mail Address of the End Customer'},
            {value: trainingType, name: 'Type of Training (Online or In Person)'},
            {value: customTrainingContent, name: 'Training Content'},
            {value: trainingLanguage, name: 'Training Language'},
            {value: trainingDuration, name: 'Training Duration'},
            {value: expectedSolution, name: 'Training Expectations'},
            {value: productVersion, name: 'zenon Version'},
        ];

        requiredFields.forEach(field => {
            if (!field.value) {
                missingFields.push(field.name);
                if (field.setError) {
                    field.setError(true);
                }
            }

        });

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailRegex.test(end_customer_mail)) {
            // If the email is not in the correct format, show an alert
            alert('Please enter a valid email address for the end customer');
            return;
        }

        if (missingFields?.length > 0) {
            // Log the missing fields to the console
            setOpenMissingFieldsDialog(true);
            alert('Please fill out the following fields:\n' + missingFields.join('\n'));
            console.log('Missing Fields: ', missingFields.join(', '));
            return;
        }

        // Show the progress bar dialog before starting the upload
        setOpenProgressBar(true);

        // Wait for the progress bar dialog to be fully opened
        await new Promise((resolve) => setTimeout(resolve, 500)); // Wait for 1 second as an example

        // Handle file upload and progress updates here
        await handleEducationRequest(
            {
                end_customer,
                end_customer_company,
                end_customer_mail,
                description,
                trainingType,
                customTrainingContent,
                desiredDeliveryDate,
                trainingDuration,
                trainingLanguage,
                trainingLocation,
                expectedSolution,
                attendeeCount,
                training_att_skillze,
                training_att_skillsc,
                productVersion,
                additional_information,
                ticketData,
                requiredHardware,
                directContactEndCustomer,
                images,  // Include images in the form data
            },
            files,
            setOpenProgressBar,
            setOpenSuccessDialog,
            setOpenErrorDialog,
            setOpenErrorUploadDialog,
        );
        // setOpenSuccessDialog(true);
    };


    const handleCheckboxChange = () => {
        setAgreedToTerms(!agreedToTerms);
    };

    const handleCheckboxCustomerContactChange = () => {
        setDirectContactEndCustomer(!directContactEndCustomer);
    };

    const handleTrainingContentChange = (event) => {
        const selectedValue = event.target.value;
        setTrainingContent(selectedValue);
    };

    const handleZenonSkillChange = (event) => {
        const selectedValue = event.target.value;
        set_training_att_skillze(selectedValue);
    };

    const handleScadaSkillChange = (event) => {
        const selectedValue = event.target.value;
        set_training_att_skillsc(selectedValue);
    };

    const handleCustomTrainingContentChange = (event) => {
        setCustomTrainingContent(event.target.value);
    };

    const handleProblemKindChange = (event) => {
        setProblemKind(event.target.value);
    };

    const handleTrainingTypeChange = (event, newTrainingType) => {
        if (newTrainingType !== null) {
            setTrainingType(newTrainingType);
        }
    };

    const handleProductVersionChange = (event) => {
        setProductVersion(event.target.value);
    };

    const handleFileChange = (newFiles) => {
        checkFileChanges(newFiles, prevFiles, setFiles, setPrevFiles);
    };

    const handleClose = () => {
        //setOpenSuccessDialog(false);
    };

    const handleCloseError = () => {
        setOpenErrorDialog(false);
    };

    const handleDescriptionChange = (content) => {
        setDescription(content);
    };

    const handleExpectedSolutionChange = (content) => {
        setExpectedSolution(content);
    };

    const handleAdditionalInformationChange = (content) => {
        set_additional_information(content);
    };

    const handleRequiredHardWareChange = (content) => {
        setRequiredHardware(content);
    };

    const handleCustomerChange = (event) => {
        set_end_customer(event.target.value);
    };

    const handleMailChange = (event) => {
        set_end_customer_mail(event.target.value);
    };

    const handleDurationChange = (event) => {
        setTrainingDuration(event.target.value);
    };

    const handleLanguageChange = (event) => {
        setTrainingLanguage(event.target.value);
    };

    const handleLocationChange = (event) => {
        setTrainingLocation(event.target.value);
    };

    const handleAttCountChange = (event) => {
        setAttendeeCount(event.target.value);
    };

    const handleCompanyChange = (event) => {
        set_end_customer_company(event.target.value);
    };

    // Gets executed once at startup
    useEffect(() => {
        localStorage.removeItem('localFileData');
    }, []);

    // Convert the 'images' state to a JSON string
    const imagesJson = JSON.stringify(images);

    useEffect(() => {
        if (imagesInputRef.current) {
            // Update the value of the ref
            imagesInputRef.current.value = imagesJson;
        }
    }, [imagesJson]); // Run this effect whenever 'imagesJson' changes

    return (

        <form onSubmit={handleButtonClick}>

            <ProgressBarDialog
                open={openProgressBar}
                onClose={() => setOpenProgressBar(false)}
                progress={progress}
                setProgress={setProgress} // Pass the setProgress function here
            />

            <Card style={{ marginBottom:100 }}>
                <center>
                    <Typography style={{ marginBottom: 20, marginTop: 30, fontFamily: 'Arimo', fontWeight: 'bold' }} variant="h5">
                        Education Service Request
                    </Typography>
                </center>

                {/* Hidden input to cache local images */}
                <input type="hidden" name="images" ref={imagesInputRef}/>

                {/* Insert Selector which allows to set the kind of problem */}
                <div style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin:50, marginTop:20, marginBottom: 20 }}>
                    <div style={{ background: '#202D4F', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', padding: '10px', textAlign: 'center' }}>
                        <Typography style={{ fontFamily: 'Arimo', color:'white' }} variant="h6">
                            What do you want to request?
                        </Typography>
                    </div>


                    {/* Problem Type Selector */}
                    <FormControl fullWidth variant="outlined" style={{ margin: 30, marginTop:30}}>
                        <InputLabel id="problem-type-label">Type of Request</InputLabel>
                        <Select
                            labelId="problem-type-label"
                            id="problem-type-select"
                            value={problem_kind}
                            onChange={handleProblemKindChange}
                            label="Type of Request"
                            style={{paddingRight:20, marginRight:50}}
                        >
                            <MenuItem value="">
                                <em>Please select</em>
                            </MenuItem>
                            <MenuItem value="customer-training">Training / workshop for a customer</MenuItem>
                            <MenuItem value="video-upload">zenon Academy Video Upload (Upload existing video)</MenuItem>
                            <MenuItem value="new-video">zenon Tutorial Video Request (Create new tutorial)</MenuItem>
                            <MenuItem value="zenon-acadamy-points">Workshop Attendance Reward</MenuItem>
                        </Select>
                    </FormControl>
                </div>


                {(problem_kind === 'customer-training')  && (
                    <>

                        <div style={{border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin: 50}}>
                            <div style={{
                                background: '#202D4F',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                padding: '10px',
                                textAlign: 'center'
                            }}>
                                <Typography style={{fontFamily: 'Arimo', color: 'white'}} variant="h6">
                                    Customer Information
                                </Typography>
                            </div>
                            <Grid style={{margin: 20}} container spacing={2}>

                                <Grid item xs={11} style={{marginTop: -20, marginBottom: 10}}>

                                    <Typography style={{fontFamily: 'Arimo', marginBottom: 10}}>
                                        To order a customer/external training please fill out the following fields.
                                    </Typography>

                                    <Typography style={{fontFamily: 'Arimo', fontWeight: 'bold'}}>
                                        Please make sure that your customer is informed about the possibilities of the
                                        zenon
                                        Academy all participants should be registered there before the training starts!
                                    </Typography>

                                </Grid>

                                <Grid item xs={10} sm={11}>
                                    <TextField
                                        label="End-Customer *"
                                        variant="outlined"
                                        fullWidth
                                        value={end_customer}
                                        onChange={handleCustomerChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Face3Icon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={10} sm={11}>
                                    <TextField
                                        label="Company of End-Customer *"
                                        variant="outlined"
                                        fullWidth
                                        value={end_customer_company}
                                        onChange={handleCompanyChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ApartmentIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={10} sm={11}>
                                    <TextField
                                        label="Mail of End-Customer *"
                                        variant="outlined"
                                        fullWidth
                                        value={end_customer_mail}
                                        onChange={handleMailChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AlternateEmailIcon />
                                                </InputAdornment>
                                            ),
                                        }}

                                    />
                                </Grid>

                                <Grid item xs={10} sm={11}>
                                    <FormControlLabel
                                        style={{fontFamily:'Arimo'}}
                                        control={
                                            <Checkbox
                                                checked={directContactEndCustomer}
                                                onChange={handleCheckboxCustomerContactChange}
                                                color="primary"
                                            />
                                        }
                                        label="Please directly contact my end-customer to clarify details."
                                    />
                                </Grid>



                                <Grid item xs={12} style={{marginLeft:-50}}>
                                    <TrixEditor
                                        inputId="description_input"
                                        label="General Information"
                                        description="Please tell us about the current training state of the customer, the related company and industry the company works in and what's the intended use-case for the training."
                                        onChange={handleDescriptionChange}
                                        required={true}
                                        setImages={setImages}
                                        images={images}
                                        ref={descriptionRef}
                                    />
                                </Grid>



                            </Grid>
                        </div>

                        <div style={{border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin: 50}}>
                            <div style={{
                                background: '#202D4F',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                padding: '10px',
                                textAlign: 'center'
                            }}>
                                <Typography style={{fontFamily: 'Arimo', color: 'white'}} variant="h6">
                                    Training Information
                                </Typography>
                            </div>
                            <Grid style={{ margin: 20 }} container spacing={2}>

                                <Grid item xs={11} style={{marginTop:-20}}>
                                    <Typography style={{fontFamily: 'Arimo', fontSize: 18, marginBottom:20 }} variant="h6">
                                        General Information about the required training
                                    </Typography>

                                    <Typography style={{ marginBottom: 10, fontFamily: 'Arimo' }}>How do you want to conduct the training?</Typography>
                                </Grid>

                                <Grid item xs={11} md={11} style={{ marginTop: -20, marginBottom: 10 }}>
                                    <ToggleButtonGroup
                                        value={trainingType}
                                        exclusive
                                        onChange={handleTrainingTypeChange}
                                        aria-label="training type"
                                    >
                                        <ToggleButton value="Face-To-Face" aria-label="face-to-face">
                                            <PeopleIcon />
                                            <Typography style={{marginLeft:15, fontSize:15, fontFamily: 'Arimo'}}>Face-To-Face Training</Typography>

                                        </ToggleButton>
                                        <ToggleButton value="Online" aria-label="online">
                                            <LanguageIcon />
                                            <Typography style={{marginLeft:15, fontSize:15, fontFamily: 'Arimo'}}>Live Online Training</Typography>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>

                                <Grid item xs={11} md={11} style={{ marginBottom: 10 }}>
                                    <Typography style={{ marginBottom: 10, fontFamily: 'Arimo' }}>What should be trained? *</Typography>

                                    <TextField
                                        label="What should the training be about?"
                                        variant="outlined"
                                        fullWidth
                                        value={customTrainingContent}
                                        style={{marginBottom:20}}
                                        onChange={handleCustomTrainingContentChange}
                                    />

                                    <Typography style={{ marginTop: 10, fontFamily: 'Arimo' }}>Please let us know more about the training:</Typography>

                                </Grid>

                                <Grid item xs={11} sm={6}>

                                    <FormControl fullWidth className="datetime_style">
                                        <InputLabel htmlFor="desiredDeliveryDate">Desired Starting Date</InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                className="datepicker"
                                                id="desiredDeliveryDate"
                                                value={desiredDeliveryDate}
                                                onChange={(date) => setDesiredDeliveryDate(date)}
                                                format="DD.MM.YYYY" // Set the desired format here
                                            />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={11} sm={5}>
                                    <TextField
                                        label="Duration *"
                                        variant="outlined"
                                        fullWidth
                                        value={trainingDuration}
                                        onChange={handleDurationChange}
                                        style={{ width: '100%' }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <TimelapseIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={11} sm={6}>
                                    <TextField
                                        label="Training Language *"
                                        variant="outlined"
                                        fullWidth
                                        value={trainingLanguage}
                                        onChange={handleLanguageChange}
                                        style={{ width: '100%' }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <TranslateIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                {trainingType !== "Online" && (
                                    <Grid item xs={11} sm={5}>
                                        <TextField
                                            label="Location"
                                            variant="outlined"
                                            fullWidth
                                            value={trainingLocation}
                                            onChange={handleLocationChange}
                                            style={{ width: '100%' }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <FmdGoodIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12} style={{marginLeft:-50}}>
                                    <TrixEditor
                                        inputId="what_do_you_want"
                                        label="Expectations for the training / workshop"
                                        description="What's the desired outcome of the training or workshop?"
                                        onChange={handleExpectedSolutionChange}
                                        required="true"
                                        setImages={setImages}
                                        images={images}
                                        ref={what_do_you_wantRef}
                                    />
                                </Grid>


                            </Grid>
                        </div>

                        <div style={{border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin: 50}}>
                            <div style={{
                                background: '#202D4F',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                padding: '10px',
                                textAlign: 'center'
                            }}>
                                <Typography style={{fontFamily: 'Arimo', color: 'white'}} variant="h6">
                                    Attendee Information
                                </Typography>
                            </div>
                            <Grid style={{ margin: 20 }} container spacing={2}>

                                <Grid item xs={11} style={{marginTop:-20}}>
                                    <Typography style={{fontFamily: 'Arimo', fontSize: 18 }} variant="h6">
                                        Let us know more about who will visit the training, so we can prepare accordingly.
                                    </Typography>
                                </Grid>

                                <Grid item xs={11} sm={5}>
                                    <TextField
                                        label="Number of attendees"
                                        variant="outlined"
                                        fullWidth
                                        value={attendeeCount}
                                        onChange={handleAttCountChange}
                                        style={{ width: '100%' }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <GroupsIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}></Grid>

                                <Grid item xs={11} md={5} style={{ marginBottom: 10 }}>
                                    <Typography style={{ marginBottom: 10, fontFamily: 'Arimo' }}>How well do the attendees know zenon?</Typography>

                                    <Select
                                        fullWidth
                                        value={training_att_skillze}
                                        onChange={handleZenonSkillChange}
                                    >

                                        <MenuItem value="I don't know">I don't know</MenuItem>
                                        <MenuItem value="No knowledge at all">No knowledge at all</MenuItem>
                                        <MenuItem value="Beginner">Beginner</MenuItem>
                                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                                        <MenuItem value="Advanced">Advanced</MenuItem>
                                        <MenuItem value="Expert">Expert</MenuItem>
                                    </Select>
                                </Grid>

                                <Grid item xs={11} md={5} style={{ marginBottom: 10 }}>
                                    <Typography style={{ marginBottom: 10, fontFamily: 'Arimo' }}>How experienced are the attendees in SCADA?</Typography>

                                    <Select
                                        fullWidth
                                        value={training_att_skillsc}
                                        onChange={handleScadaSkillChange}
                                    >

                                        <MenuItem value="I don't know">I don't know</MenuItem>
                                        <MenuItem value="No knowledge at all">No knowledge at all</MenuItem>
                                        <MenuItem value="Beginner">Beginner</MenuItem>
                                        <MenuItem value="Intermediate">Intermediate</MenuItem>
                                        <MenuItem value="Advanced">Advanced</MenuItem>
                                        <MenuItem value="Expert">Expert</MenuItem>
                                    </Select>
                                </Grid>




                            </Grid>
                        </div>


                        <div style={{border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin: 50}}>
                            <div style={{
                                background: '#202D4F',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                padding: '10px',
                                textAlign: 'center'
                            }}>
                                <Typography style={{fontFamily: 'Arimo', color: 'white'}} variant="h6">
                                    Required Resources
                                </Typography>
                            </div>
                            <Grid style={{ margin: 20 }} container spacing={2}>

                                <Grid item xs={11} sm={5} style={{marginTop:-10}}>
                                    <Typography style={{ marginBottom: 20, fontFamily: 'Arimo' }}>For which version do you need the training?</Typography>

                                    <VersionSelector
                                        productVersion={productVersion}
                                        handleProductVersionChange={handleProductVersionChange}
                                    />

                                </Grid>

                                <Grid item xs={12} style={{marginLeft:-50}}>
                                    <TrixEditor
                                        inputId="what_do_you_want"
                                        label="Required hardware / software"
                                        description="Please let us know if training hardware (Laptops, VMs...) or software (zenon licenses) will be needed"
                                        onChange={handleRequiredHardWareChange}
                                        required={false}
                                        setImages={setImages}
                                        images={images}
                                        ref={requiredHardWareRef}
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <div style={{border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin: 50}}>
                            <div style={{
                                background: '#202D4F',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                padding: '10px',
                                textAlign: 'center'
                            }}>
                                <Typography style={{fontFamily: 'Arimo', color: 'white'}} variant="h6">
                                    Additional Information
                                </Typography>
                            </div>
                            <Grid style={{ margin: 20 }} container spacing={2}>
                                <Grid item xs={12} style={{marginLeft:-50, marginTop:-20}}>
                                    <TrixEditor
                                        inputId="additional_information"
                                        label="Anything to add?"
                                        description="If you want to add something else, please let us know here..."
                                        onChange={handleAdditionalInformationChange}
                                        required={false}
                                        setImages={setImages}
                                        images={images}
                                        ref={additionalInformationRef}
                                    />
                                </Grid>

                            </Grid>
                        </div>

                        <div style={{border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin: 50}}>
                            <div style={{
                                background: '#202D4F',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                padding: '10px',
                                textAlign: 'center'
                            }}>
                                <Typography style={{fontFamily: 'Arimo', color: 'white'}} variant="h6">
                                    Files & Logs
                                </Typography>
                            </div>

                            <div className="file-upload-container" style={{margin: 20}}>
                                <Typography style={{fontFamily: 'Arimo', marginBottom: 20}}>Here you can attach files if needed:</Typography>

                                {fileshareData?.length > 0 && (  // Conditionally render the specific Box
                                    <Box bgcolor="#357a38" p={5} m={2} borderRadius={'10px'} alignItems="center">
                                        <Typography style={{fontFamily: 'Arimo', color: 'white'}} variant="h6">
                                            Yey! Some files already exist for this request!
                                        </Typography>
                                        <Typography style={{color: 'white'}}>
                                            The following files are already present in the existing Fileshare
                                            for the Ticket and do not need to be uploaded again. We will attach them to
                                            your
                                            request automatically:
                                        </Typography>
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    {fileshareData.map((file, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell style={{color: '#fff'}}>{file}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                )}

                                <FileUpload
                                    value={files}
                                    maxSize={524288000}
                                    onChange={handleFileChange}

                                    buttonText="Select Files"
                                    title="Maximum Size: 500 MB / File. Drag 'n' drop some files here, or click to select files"/>

                                <Box bgcolor="#f57c00" p={5} mt={2} borderRadius={'10px'} alignItems="center">
                                    <Typography style={{color: 'white'}}><b>Info: </b> If you raise this request from an
                                        existing ticket, the files will be uploaded to the existing file share of the
                                        ticket: All these files will be visible to the customer!
                                        For ARs without a linked ticket, a new File Share will be created.
                                        Please be aware that it can take some time that you can see the files in the
                                        file
                                        share.
                                    </Typography>
                                </Box>
                            </div>
                        </div>

                                <Box bgcolor="#EEEEEE" style={{paddingLeft:50, paddingRight:50, padding:20, margin:50, marginBottom:20, }} borderRadius={'10px'} alignItems="center">
                                    <Typography style={{fontFamily:'Arimo', marginBottom:5}}><b>Please agree before completing your request:</b></Typography>
                                    <FormControlLabel
                                        style={{fontFamily:'Arimo'}}
                                        control={
                                            <Checkbox
                                                checked={agreedToTerms}
                                                onChange={handleCheckboxChange}
                                                color="primary"
                                            />
                                        }
                                        label="I will make sure that all attendees are registered in the zenon Acadamy before the training gets started."
                                    />
                                </Box>

                                <CardActions>

                                    <div style={{marginLeft: 'auto', marginRight: 45, marginBottom:20}}>
                                        <Button
                                            style={{
                                                backgroundColor: '#202D4F',
                                                color: agreedToTerms ? '#ffffff' : '#777777'
                                            }}
                                            variant="contained"
                                            onClick={handleButtonClick}
                                            disabled={!agreedToTerms}
                                        >
                                            Request Training
                                        </Button>
                                    </div>

                                </CardActions>


                    </>

                )}


                {problem_kind === 'video-upload' && (
                    <div style={{border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin: 50}}>
                        <iframe
                            src='https://copadata.proofhub.com/link/form?token=1c8d8f5f10e56a60ac967e631bb5014bad2a7753'
                            width='100%'
                            height='1700px'
                            style={{border: 'none', borderRadius: '10px'}}
                        />
                    </div>
                )}

                {problem_kind === 'new-video' && (
                    <div style={{border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin: 50}}>
                        <iframe
                            src='https://copadata.proofhub.com/link/form?token=e3da0478444f88a621051cb28d70a62da49f219e'
                            width='100%'
                            height='1000px'
                            style={{border: 'none', borderRadius: '10px'}}
                        />
                    </div>
                )}

                {problem_kind === 'zenon-acadamy-points' && (
                    <div style={{border: '1px solid #ccc', borderRadius: '10px', padding: '0px', margin: 50}}>
                        <iframe
                            src='https://copadata.proofhub.com/link/form?token=1e40d419b13dcf6f695c079af2285e09e8bf416f'
                            width='100%'
                            height='1100px'
                            style={{border: 'none', borderRadius: '10px'}}
                        />
                    </div>
                )}




                <SuccessDialog open={openSuccessDialog} onClose={handleClose}/>
                <UploadErrorDialog open={openErrorUploadDialog} onClose={handleClose}/>
                <ErrorDialog open={openErrorDialog} onClose={handleCloseError}/>

            </Card>
        </form>
    );
}

export default EducationalRequestForm;
