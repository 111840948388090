import { removeUnwantedElements} from '../Helpers';
import { simulateLoading, uploadFilesToShare, processImageData } from '../UploadHelper';
import {getServiceCategory} from "../../Components/Statics/servicelist";

export async function process_ps_request(formData, files, setOpenProgressBar, setOpenSuccessDialog, setOpenErrorDialog, setOpenErrorUploadDialog) {

    let hasError = false;
    let createdTicketNumber = "";

    //console.log(subject, desiredDeliveryDate, desiredQuotationDate, description, expectedSolution, productVersion, additionalInfo, cf_kind_of_professional_services, subsidary)


    console.log(formData);

    // Reset values
    localStorage.removeItem("error");
    localStorage.removeItem("createdTicket");

    // zoho Ticket Creation Logic
    const currentDate = new Date();
    const oneMonthFromNow = new Date(currentDate);
    oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);

    const requesterMail = localStorage.getItem("email");
    const requesterName = localStorage.getItem('name');

    if(!requesterMail || !requesterName){
        localStorage.setItem("error", `Ticket Creation failed because of missing identification!`);
        hasError = true; // Set the error variable to true
        setOpenErrorDialog(true);
        throw new Error(`Ticket Creation failed because of missing identification!`);
    }

    // Check if all required variables are defined
    const requiredVariables = [
        { name: "subject", value: formData.subject },
        { name: "description", value: formData.description },
        { name: "endCustomer", value: formData.endCustomer },
        { name: "oneMonthFromNow", value: oneMonthFromNow },
        { name: "requesterName", value: requesterName },
        { name: "requesterMail", value: requesterMail },
        { name: "productVersion", value: formData.productVersion },
        { name: "expectedSolution", value: formData.expectedSolution },
    ];

    for (const variable of requiredVariables) {
        if (variable.value === undefined) {
            hasError = true; // Set the error variable to true
            localStorage.setItem("error", `Missing required value: ${variable.name}`);
            setOpenProgressBar(false);
            setOpenErrorDialog(true);
            throw new Error(`Missing required values.`);
        }
    }

    try {

        // Create a JSON object for the request data
        const requestData = {
            ticket_type: "Professional Services Request",
            ticket_subject: "AR | " + formData.subject,
            priority: "Medium",
            urgency: "Medium",

            // End Customer Name!
            customer: formData.endCustomer,

            // Agent Name!
            customer_mail: requesterMail,
            customer_lastname: requesterName.split(' ').pop(),
            customer_firstname: requesterName.split(' ')[0],

            subsidary: formData.subsidary,

            // Conditionally add ticket_short_id and ticket_full_id if present
            ...(formData.ticketData.ticketNumber && formData.ticketData.id ? {
                ticket_short_id: formData.ticketData.ticketNumber,
                ticket_full_id: formData.ticketData.id
            } : {}),

            ps_team: getServiceCategory(formData.cf_kind_of_professional_services),

            cf_kind_of_professional_services: formData.cf_kind_of_professional_services,

            time_horizon: formData.desiredDeliveryDate.toISOString(),
            ps_quotation_date: formData.desiredQuotationDate.toISOString(),
            product_id: formData.productVersion,
            description: formData.description,
            attachments: [],
            what_do_you_want: formData.expectedSolution,
            additional_information: formData.additionalInfo,

            // Conditionally add fileshare_link based on ticketNumber
            ...(formData.ticketData.ticketNumber && !isNaN(formData.ticketData.ticketNumber) ? {
                fileshare_link: formData.ticketData.ticketNumber > 14902
                    ? `https://fileshare.copadata.com/apps/files/?dir=/Support%20Shares/${formData.ticketData.ticketNumber}`
                    : `https://fileshare.copadata.com/apps/files/?dir=/${formData.ticketData.ticketNumber}`
            } : {}),


            // Conditionally add fileshare_link if formData.ticketData.ticketNumber is present

        };

        // Completed here if no files exist.

        if (files.length === 0) {
            const progressBar = document.getElementById('progress-bar');
            const progressText = document.getElementById('progress-text');
            simulateLoading(progressBar, progressText);
        }

        processImageData(formData, requestData);
        console.log("Sending Data:", requestData);
        console.log(JSON.stringify(requestData));


        // Send the POST request as JSON
        const response = await fetch('https://fileapi.copatools.com/api/TicketData/forward-ar', {
        //const response = await fetch('https://localhost:7142/api/TicketData/forward-ar', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
                'accept': 'application/json',
            },
            body: JSON.stringify(requestData), // Convert the object to JSON string
        });

        if (response.ok) {
            const data = await response.json();
            const json = JSON.parse(data);
            localStorage.setItem("createdTicket", json.id);
            createdTicketNumber = json.ticketNumber;
        } else {
            const data = await response.text();
            console.log("Error:", data);

            if (response.status === 503) {
                localStorage.setItem("error", "The Zoho API seems to be unavailable " +
                    "at the moment. We are sorry for the inconvenience, please keep " +
                    "this window open and retry in a few minutes. Error 503 - Service unavailable.");
            } else {
                localStorage.setItem("error", `Ticket Creation failed with error ${data} at SPUBO API.`);
            }

            hasError = true;
            setOpenErrorDialog(true);
            throw new Error(`Request failed with status ${response.status}`);
        }
    } catch (error) {
        hasError = true;
        localStorage.setItem("error", `Ticket Creation failed with error ${error.message}`);
        setOpenProgressBar(false);
        setOpenSuccessDialog(false);
        setOpenErrorDialog(true);
        return;
    }

    localStorage.removeItem('ticketId');

    if (!createdTicketNumber) {
        if (!localStorage.getItem("error")) {
            localStorage.setItem("error", `No Ticket Number set, file upload could not be completed.`);
        }

        setOpenProgressBar(false);
        setOpenSuccessDialog(false);
        setOpenErrorDialog(true);
        return;
    }

    // Handle case when no files are present
    if (files.length === 0) {
        setOpenProgressBar(false);
        setOpenSuccessDialog(true);
        setOpenErrorDialog(false);
        return;
    } else {
        // Upload files if files are present
        await uploadFilesToShare(files, setOpenProgressBar, setOpenSuccessDialog, setOpenErrorDialog, formData.ticketData?.ticketNumber, createdTicketNumber, setOpenErrorUploadDialog);
    }
}
